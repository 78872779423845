import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

export default function UserAvatar() {
    const { user } = useUser();
    const navigate = useNavigate();

    return (
        <div 
            onClick={() => navigate('/profile')}
            className="cursor-pointer w-10 h-10 rounded-full overflow-hidden flex-shrink-0"
        >
            <img
                src={user?.imageUrl || `https://ui-avatars.com/api/?name=${user?.fullName || 'User'}`}
                alt="Profile"
                className="w-full h-full object-cover"
            />
        </div>
    );
} 