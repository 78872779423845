import { useSignIn } from "@clerk/clerk-react";
import { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

export default function SignIn() {
    const { signIn, setActive } = useSignIn();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        try {
            const result = await signIn.create({
                identifier: email,
                password,
            });

            if (result.status === "complete") {
                await setActive({ session: result.createdSessionId });
                const redirectTo = location.state?.from || "/profile";
                navigate(redirectTo);
            } else {
                console.error("Sign in result: ", result);
                setError("Something went wrong. Please try again.");
            }
        } catch (err) {
            console.error("Error:", err);
            setError(err.errors?.[0]?.message || "Failed to sign in. Please check your credentials.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white p-8 rounded-3xl w-full max-w-md">
            <h1 className="text-2xl font-bold mb-8">Sign In</h1>

            {error && (
                <p className="text-red-500 mb-4">{error}</p>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div>
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full px-4 py-2 text-white bg-black rounded-md disabled:opacity-50"
                >
                    {loading ? "Signing in..." : "Sign In"}
                </button>
            </form>

            <div className="mt-4 text-center">
                <Link to="/sign-up" className="text-blue-500 hover:underline">
                    Don't have an account? Sign up
                </Link>
            </div>
        </div>
    );
} 