import React, { useState } from 'react';
import OpenAI from 'openai';
import { supabase } from '../supabaseClient';
import { useUser } from '@clerk/clerk-react';

// Debug the environment variable (remove in production)
console.log('API Key exists:', !!process.env.REACT_APP_OPENAI_API_KEY);

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

// Function to parse the energizer content from the JSON response
const parseEnergizerContent = (content) => {
  try {
    return JSON.parse(content);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    // Handle parsing error
    return null;
  }
};

// Helper function to convert markdown-style bold to JSX
const formatBoldText = (text) => {
  return text.split(/\*\*/).map((part, index) =>
    index % 2 === 1 ? <strong key={index}>{part}</strong> : part
  );
};

export default function CheckInGenerator() {
  const { user } = useUser();

  // State variables
  const [selectedType, setSelectedType] = useState('check-in');
  const [selectedMood, setSelectedMood] = useState('curious');
  const [selectedComplexity, setSelectedComplexity] = useState('simple');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedQuestion, setGeneratedQuestion] = useState(null);
  const [previousQuestions, setPreviousQuestions] = useState([]);

  // Handler functions
  const handleTypeChange = (type) => {
    setSelectedType(type);
    setGeneratedQuestion(null);
    setPreviousQuestions([]); // Clear previous questions when type changes
  };

  const handleMoodChange = (mood) => {
    setSelectedMood(mood);
  };

  const handleComplexityChange = (complexity) => {
    setSelectedComplexity(complexity);
  };

  // Function to track generated questions
  const trackGeneratedQuestion = async (question) => {
    if (!user || !question) return;

    try {
      const { error } = await supabase
        .from('generated_questions')
        .insert([
          {
            user_id: user.id,
            question: question,
            created_at: new Date().toISOString(),
          },
        ]);

      if (error) {
        console.error('Error tracking question:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error saving generated question:', error);
    }
  };

  // Function to generate questions or energizers
  const generateQuestion = async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      let systemPrompt;
      let userPrompt;
  
      if (selectedType === 'energizer') {
        systemPrompt = `You are an expert facilitator specializing in creating team exercises that promote engagement, trust, and psychological safety. Your exercises align with the philosophies of Brené Brown and Bruce Tuckman, fostering vulnerability, empathy, and supporting teams through their development stages.`;
  
        userPrompt = `Please generate a ${selectedComplexity}, group energizer exercise with a ${selectedMood} tone. The exercise should encourage vulnerability, trust, and connection among team members. Ensure the steps are clear, brief, and the purpose aligns with fostering psychological safety and supporting team development stages.
  
  Respond in the following JSON format without deviation:
  
  {
    "title": "Creative, Engaging Title",
    "emoji": "An appropriate emoji that represents the energizer",
    "description": "A brief overview of the energizer",
    "timeFrame": "Duration in minutes",
    "groupSize": "Number of participants or size range",
    "materials": "Required items or 'None'",
    "purpose": "Brief description of benefits and goals",
    "steps": ["Step 1", "Step 2", "Step 3"],
    "facilitatorNotes": ["Note 1", "Note 2", "Note 3"]
  }`;
      } else {
        systemPrompt = `You are an expert in crafting discussion questions that spark meaningful conversations, encourage authentic sharing, and foster psychological safety. Your questions align with the philosophies of Brené Brown and Bruce Tuckman, promoting vulnerability, trust, and supporting teams through their development stages.`;
  
        if (selectedComplexity === 'simple') {
          // Define simple question templates based on type and mood
          const simpleTemplates = {
            'check-in': {
              'curious': [
                'that encourages sharing a recent positive experience',
                'that invites team members to express gratitude',
                'that helps team members share something they are looking forward to',
              ],
              'personal': [
                'that allows team members to share a personal strength',
                'that invites sharing a recent challenge overcome',
                'that encourages expressing feelings about the day',
              ],
              'weird': [
                'that playfully invites vulnerability',
                'that uses metaphors to express current emotions',
                'that encourages sharing an unusual personal insight',
              ],
            },
            'check-out': {
              'curious': [
                'that reflects on what was learned during the session',
                'that invites sharing appreciation for a team member',
                'that encourages expressing hopes for the next meeting',
              ],
              'personal': [
                'that allows team members to share a personal takeaway',
                'that invites reflection on personal growth',
                'that encourages expressing feelings about the teams progress',
              ],
              'weird': [
                'that playfully reflects on the days events',
                'that uses creative analogies to describe experiences',
                'that invites sharing a humorous observation from the session',
              ],
            },
          };
  
          // Select a random template
          const templates = simpleTemplates[selectedType][selectedMood];
          if (!templates) {
            setError('No templates available for the selected type and mood.');
            setIsLoading(false);
            return;
          }
          const randomTemplate =
            templates[Math.floor(Math.random() * templates.length)];
  
          userPrompt = `Please create a simple, one-sentence ${selectedType} question ${randomTemplate}.
  
  Requirements:
  - The question should be a complete sentence.
  - Use 6-12 words.
  - Use inclusive and empathetic language.
  - Avoid yes/no questions.
  - Encourage sharing and vulnerability in a safe manner.
  - Do not repeat any of the previous questions: ${previousQuestions.slice(-5).join(', ')}.
  
  Provide only the question without any additional text.`;
        } else {
          // For moderate and deep complexities
          const questionTypes = [
            'a question that fosters vulnerability and trust',
            'a prompt that encourages deep reflection',
            'a scenario that supports team development stages',
            'an inquiry that builds empathy and connection',
            'a thought-provoking question inspired by Brené Brown’s work',
          ];
  
          const randomQuestionType =
            questionTypes[Math.floor(Math.random() * questionTypes.length)];
  
          userPrompt = `Please generate ${randomQuestionType} as a unique, one-sentence ${selectedComplexity} ${selectedType} question with a ${selectedMood} tone. The question should promote psychological safety and align with the philosophies of Brené Brown and Bruce Tuckman.
  
  Requirements:
  - The question must be a complete sentence.
  - Use inclusive and empathetic language.
  - Avoid common prompts or clichés.
  - Encourage authentic sharing and support team development.
  
  Do not provide any additional text or explanations. Respond with the question only.`;
  
          // Include previous questions in the prompt to avoid repetition
          if (previousQuestions.length > 0) {
            userPrompt += `
  
  Previously generated questions:
  - ${previousQuestions.slice(-5).join('\n- ')}
  
  Please ensure the new question is different from these.`;
          }
        }
      }
  
      const completion = await openai.chat.completions.create({
        messages: [
          { role: 'system', content: systemPrompt },
          { role: 'user', content: userPrompt },
        ],
        model: 'gpt-4',
        max_tokens: selectedType === 'energizer' ? 500 : 50,
        temperature: selectedType === 'energizer' ? 0.7 : 0.85,
        top_p: 0.9,
        frequency_penalty: selectedType === 'energizer' ? 0.5 : 1.2,
        presence_penalty: selectedType === 'energizer' ? 0.5 : 1.0,
      });
  
      let response = completion.choices[0].message.content.trim();
  
      if (selectedType === 'energizer') {
        const parsedContent = parseEnergizerContent(response);
        if (parsedContent) {
          setGeneratedQuestion(parsedContent);
          await trackGeneratedQuestion(parsedContent);
        } else {
          setError('Failed to parse the energizer. Please try again.');
        }
      } else {
        // Remove any leading and trailing quotation marks
        response = response.replace(/^["']+|["']+$/g, '');
  
        setGeneratedQuestion(response);
        setPreviousQuestions([...previousQuestions, response]);
        await trackGeneratedQuestion(response);
      }
    } catch (err) {
      if (err.response && err.response.status === 429) {
        setError('Rate limit exceeded. Please wait a moment and try again.');
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateQuestion = async () => {
    try {
      // Get current session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) {
        console.error('Session error:', sessionError);
        return;
      }

      if (!session) {
        console.log('No active session');
        // Redirect to login or show auth message
        return;
      }

      console.log('Current user:', session.user.id);

      // Add user_id to question data
      const questionData = {
        user_id: session.user.id,
        question: generatedQuestion,
        created_at: new Date().toISOString()
      };

      console.log('Attempting to insert:', questionData);

      const { data, error } = await supabase
        .from('generated_questions')
        .insert([questionData])
        .select();

      if (error) {
        console.error('Insert error:', error);
        return;
      }

      console.log('Question saved:', data);

    } catch (error) {
      console.error('Error in handleGenerateQuestion:', error);
    }
  };

  return (
    <div className="w-full max-w-5xl mx-auto space-y-4 md:space-y-6 px-0 pt-2">
      {/* Output Area */}
      <div className="bg-white rounded-3xl shadow-lg py-4 px-3 md:py-12 md:px-16">
        {!generatedQuestion ? (
          <h2 className="text-4xl md:text-6xl font-bold text-center text-gray-400">
            Click 'Generate' to get your{' '}
            {selectedType === 'check-in'
              ? 'check-in question'
              : selectedType === 'check-out'
              ? 'check-out question'
              : 'team exercise'}
          </h2>
        ) : selectedType === 'energizer' &&
          typeof generatedQuestion === 'object' ? (
          <div className="space-y-6 md:space-y-8">
            <h2 className="text-2xl md:text-4xl font-bold">
              {generatedQuestion.title} {generatedQuestion.emoji}
            </h2>

            <div>
              {/* <p className="font-semibold text-xl mb-2">Description</p> */}
              <p>{generatedQuestion.description}</p>
            </div>

            <div className="bg-gray-100 p-4 md:p-6 rounded-xl">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                <div>
                  <p className="font-semibold">Time Frame:</p>
                  <p>{generatedQuestion.timeFrame}</p>
                </div>
                <div>
                  <p className="font-semibold">Group Size:</p>
                  <p>{generatedQuestion.groupSize}</p>
                </div>
                <div>
                  <p className="font-semibold">Materials:</p>
                  <p>{generatedQuestion.materials}</p>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <p className="font-semibold text-xl mb-2">Purpose</p>
                <p>{generatedQuestion.purpose}</p>
              </div>
              <div className="space-y-6">
                {generatedQuestion.steps.map((step, index) => (
                  <div key={index}>
                    <p className="font-semibold text-xl mb-2">
                      Step {index + 1}
                    </p>
                    <p>{formatBoldText(step.trim())}</p>
                  </div>
                ))}
              </div>
              {generatedQuestion.facilitatorNotes &&
                generatedQuestion.facilitatorNotes.length > 0 && (
                  <div>
                    <p className="font-semibold text-xl mb-2">
                      Facilitator Notes
                    </p>
                    <ul className="list-disc pl-5 space-y-2">
                      {generatedQuestion.facilitatorNotes.map((note, index) => (
                        <li key={index}>
                          {note.replace(/^Note \d+: /, '')}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>
        ) : (
          <h2 className="text-4xl md:text-6xl font-bold text-center">
            {generatedQuestion}
          </h2>
        )}
      </div>

      {/* Controls Area */}
      <div className="bg-white rounded-3xl shadow-lg p-4 md:p-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 mb-8 md:mb-12">
          {/* Type Column */}
          <div>
            <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Type</p>
            <div className="space-y-1 md:space-y-2">
              <label className="flex items-center justify-between text-xl">
                <span>🔍 Check-In</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedType === 'check-in'}
                    onChange={() => handleTypeChange('check-in')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedType === 'check-in'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
              <label className="flex items-center justify-between text-xl">
                <span>⚡️ Energizer</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedType === 'energizer'}
                    onChange={() => handleTypeChange('energizer')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedType === 'energizer'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
              <label className="flex items-center justify-between text-xl">
                <span>🏁 Check-Out</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedType === 'check-out'}
                    onChange={() => handleTypeChange('check-out')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedType === 'check-out'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </div>

          {/* Mood Column */}
          <div>
            <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Mood</p>
            <div className="space-y-1 md:space-y-2">
              <label className="flex items-center justify-between text-xl">
                <span>🤔 Curious</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedMood === 'curious'}
                    onChange={() => handleMoodChange('curious')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedMood === 'curious'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
              <label className="flex items-center justify-between text-xl">
                <span>👤 Personal</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedMood === 'personal'}
                    onChange={() => handleMoodChange('personal')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedMood === 'personal'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
              <label className="flex items-center justify-between text-xl">
                <span>🌀 Weird</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedMood === 'weird'}
                    onChange={() => handleMoodChange('weird')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedMood === 'weird' ? 'bg-green-500' : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </div>

          {/* Complexity Column */}
          <div>
            <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">
              Complexity
            </p>
            <div className="space-y-1 md:space-y-2">
              <label className="flex items-center justify-between text-xl">
                <span>✅ Simple</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedComplexity === 'simple'}
                    onChange={() => handleComplexityChange('simple')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedComplexity === 'simple'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
              <label className="flex items-center justify-between text-xl">
                <span>🔸 Moderate</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedComplexity === 'moderate'}
                    onChange={() => handleComplexityChange('moderate')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedComplexity === 'moderate'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
              <label className="flex items-center justify-between text-xl">
                <span>🧠 Deep</span>
                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                  <input
                    type="checkbox"
                    checked={selectedComplexity === 'deep'}
                    onChange={() => handleComplexityChange('deep')}
                    className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
                  />
                  <div
                    className={`w-full h-full rounded-full ${
                      selectedComplexity === 'deep'
                        ? 'bg-green-500'
                        : 'bg-gray-200'
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </div>
        </div>

        {error && (
          <div className="text-red-500 text-center mb-4">{error}</div>
        )}

        <button
          onClick={generateQuestion}
          disabled={isLoading}
          className={`w-full bg-green-500 text-white text-lg md:text-xl font-semibold py-3 md:py-4 rounded-lg ${
            isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'
          }`}
        >
          {isLoading ? 'Generating...' : 'Generate'}
        </button>
      </div>
    </div>
  );
}
