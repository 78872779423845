import { useSignUp } from "@clerk/clerk-react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { supabase } from "../supabaseClient";

export default function SignUp() {
    const { signUp, setActive } = useSignUp();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [pendingVerification, setPendingVerification] = useState(false);
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        
        try {
            // 1. Sign up the user first
            const { data: authData, error: signUpError } = await supabase.auth.signUp({
                email,
                password
            });

            if (signUpError) {
                console.error('Signup error:', signUpError);
                throw signUpError;
            }

            console.log('Auth successful:', authData);

            // 2. Create user profile
            if (authData.user) {
                const profileData = {
                    user_id: authData.user.id,
                    full_name: fullName,
                    bio: '', // Optional
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                };

                console.log('Creating profile:', profileData);

                const { error: profileError } = await supabase
                    .from('user_profiles')
                    .insert([profileData]);

                if (profileError) {
                    console.error('Profile creation error:', profileError);
                    throw profileError;
                }

                console.log('Profile created successfully');
            }

            // Success - you can redirect or show success message
            
        } catch (error) {
            console.error('Error in signup process:', error);
            setError(error.message);
        }
    };

    const handleVerification = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        try {
            const result = await signUp.attemptEmailAddressVerification({
                code
            });

            if (result.status === "complete") {
                // First set the session
                await setActive({ session: result.createdSessionId });
                
                // Then update the user's metadata
                const user = result.createdUserId;
                if (user) {
                    try {
                        await user.update({
                            unsafeMetadata: {
                                fullName: fullName
                            }
                        });
                    } catch (updateError) {
                        console.error("Error updating user metadata:", updateError);
                        // Continue with navigation even if metadata update fails
                    }
                }
                
                navigate("/profile");
            } else {
                setError("Verification failed. Please try again.");
            }
        } catch (err) {
            console.error("Error:", err);
            setError(err.errors?.[0]?.message || "Failed to verify email. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white p-8 rounded-3xl w-full max-w-md">
            <h1 className="text-2xl font-bold mb-8">Sign Up</h1>

            {error && (
                <p className="text-red-500 mb-4">{error}</p>
            )}

            {!pendingVerification ? (
                <form onSubmit={handleSignUp} className="space-y-4">
                    <div>
                        <label htmlFor="fullName">Full Name</label>
                        <input
                            id="fullName"
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full px-4 py-2 text-white bg-black rounded-md disabled:opacity-50"
                    >
                        {loading ? "Creating account..." : "Sign Up"}
                    </button>
                </form>
            ) : (
                <form onSubmit={handleVerification} className="space-y-4">
                    <div>
                        <label htmlFor="code">Verification Code</label>
                        <input
                            id="code"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full px-4 py-2 text-white bg-black rounded-md disabled:opacity-50"
                    >
                        {loading ? "Verifying..." : "Verify Email"}
                    </button>
                </form>
            )}

            <div className="mt-4 text-center">
                <Link to="/sign-in" className="text-blue-500 hover:underline">
                    Already have an account? Sign in
                </Link>
            </div>
        </div>
    );
} 