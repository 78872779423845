import React from 'react';

export default function About() {
    return (
        <div className="w-full max-w-5xl mx-auto bg-white rounded-3xl shadow-lg p-12">
            <h2 className="text-3xl font-bold mb-6">Creating Connections that Drive Engagement</h2>
            
            <p className="mb-8 text-gray-700">
                Check-In Questions is an interactive platform designed to strengthen connections, improve communication, and foster psychological safety in any group setting. Our tool helps teams build the foundations of trust and respect, supporting growth through stages of collaboration and development. By creating a safe environment, Check-In Questions empowers team members to express themselves openly, cultivating a positive group culture where everyone feels valued.
            </p>

            <h3 className="text-2xl font-bold mb-4">How Team Development Works</h3>
            <p className="mb-4 text-gray-700">As teams come together, they move through distinct phases of development, and each phase requires different types of support to build cohesion and effectiveness:</p>
            <ul className="list-disc pl-6 mb-8 text-gray-700 space-y-2">
                <li><span className="font-semibold">Building Trust and Openness:</span> In the early stages, it's crucial to create an inclusive atmosphere where everyone feels comfortable sharing ideas. This initial openness lays the groundwork for team cohesion and trust.</li>
                <li><span className="font-semibold">Navigating Differences Constructively:</span> As the team evolves, challenges and conflicts may arise. Having a safe space for open, constructive conversations enables teams to handle differences respectfully, finding common ground that moves them forward.</li>
                <li><span className="font-semibold">Establishing a Collaborative Culture:</span> Once a shared understanding is achieved, a culture of collaboration and psychological safety flourishes. Team members feel confident contributing their unique perspectives, which enhances creativity and problem-solving.</li>
                <li><span className="font-semibold">Empowering High Performance:</span> With strong foundations in place, the team can now operate at its best. Open communication and mutual support empower team members to take on challenges, innovate, and perform at a high level.</li>
            </ul>

            <h3 className="text-2xl font-bold mb-4">Three Types of Questions and Activities</h3>
            <p className="mb-4 text-gray-700">Our tool offers a range of activities that support each stage of development, helping to create a positive group dynamic and reinforce bonds among team members:</p>
            <ul className="list-disc pl-6 mb-8 text-gray-700 space-y-4">
                <li><span className="font-semibold">🔍 Check-In Questions:</span> Perfect for starting meetings or sessions, these questions help participants transition into the space and connect with the group.</li>
                <li><span className="font-semibold">⚡️ Icebreakers & Team Energizers:</span> Fun, interactive exercises designed to boost energy, encourage movement, and create a relaxed atmosphere. These activities help break down social barriers and get everyone engaged through playful interaction.</li>
                <li><span className="font-semibold">🏁 Check-Out Questions:</span> Thoughtful prompts for ending sessions, helping participants reflect on their experience and leave with a sense of closure.</li>
            </ul>

            <h3 className="text-2xl font-bold mb-4">AI-Driven Customization</h3>
            <p className="mb-8 text-gray-700">
                Using advanced AI from OpenAI, Check-In Questions generates unique and customizable prompts tailored to your group's needs. Whether you're looking for a curious, reflective, or playful tone—or prefer simple to complex depth—our AI-powered prompts adapt to align with your session's goals. This flexibility keeps interactions fresh, engaging, and suitable for both professional and casual settings.
            </p>

            <h3 className="text-2xl font-bold mb-4">Where to Use Check-In Questions</h3>
            <p className="mb-4 text-gray-700">Check-In Questions enhances any group environment. Use it to set the tone, foster open communication, and support psychological safety in a variety of settings:</p>
            <ul className="list-disc pl-6 mb-8 text-gray-700 space-y-2">
                <li>Corporate Meetings: Break down silos and foster a collaborative spirit</li>
                <li>Team-Building Sessions: Encourage transparency and create connections</li>
                <li>Classrooms: Engage students, spark active participation, and promote critical thinking</li>
                <li>Remote Gatherings: Bring people together meaningfully, bridging digital divides</li>
            </ul>

            <h3 className="text-2xl font-bold mb-4">Who Can Benefit?</h3>
            <p className="mb-4 text-gray-700">Check-In Questions is ideal for anyone invested in building authentic connections and strengthening group dynamics:</p>
            <ul className="list-disc pl-6 mb-8 text-gray-700 space-y-2">
                <li>Leaders and Managers: Fostering cohesive, high-performing teams</li>
                <li>Facilitators and Coaches: Guiding dynamic discussions and meaningful interactions</li>
                <li>Educators: Encouraging active engagement and collaborative learning</li>
                <li>Agile Teams: Supporting openness and continuous improvement</li>
            </ul>

            <h3 className="text-2xl font-bold mb-4">A Foundation of Proven Principles</h3>
            <p className="text-gray-700">
                Our approach to team development and connection-building draws on concepts of vulnerability, psychological safety, and group dynamics as championed by experts such as <a href="https://en.wikipedia.org/wiki/Bren%C3%A9_Brown" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Brené Brown</a> and <a href="https://en.wikipedia.org/wiki/Tuckman%27s_stages_of_group_development" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Bruce Tuckman</a>. These principles are the foundation of meaningful, productive collaboration and lasting group success.
            </p>
        </div>
    );
} 