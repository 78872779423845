import React from 'react';

export default function Contact() {
    return (
        <div className="w-full max-w-5xl mx-auto bg-white rounded-3xl shadow-lg p-12">
            <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
            
            <p className="mb-8 text-gray-700">
                Have questions, suggestions, or feedback? I'd love to hear from you! Contact me and read more at: <a href="https://jacoblangvadnilsson.com/" className="text-blue-500 hover:text-blue-600">https://jacoblangvadnilsson.com/</a> 

            </p>
        </div>
    );
}
