import { useUser, useClerk } from "@clerk/clerk-react";
import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";

function UserProfile() {
    const { user } = useUser();
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');
    const [stats, setStats] = useState({
        generatedCount: 0,
        savedCount: 0
    });
    const clerk = useClerk();
    const navigate = useNavigate();
    const [activeForm, setActiveForm] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [emailToVerify, setEmailToVerify] = useState(null);
    const [showVerificationInput, setShowVerificationInput] = useState(false);

    useEffect(() => {
        if (user) {
            setName(user.unsafeMetadata?.fullName || '');
            fetchUserProfile();
            fetchUserStats();
        }
    }, [user]);

    const fetchUserProfile = async () => {
        try {
            // First check if we have a valid session
            const { data: { session } } = await supabase.auth.getSession();
            
            if (!session) {
                console.log('No active session found');
                return;
            }

            console.log('Fetching profile for user:', session.user.id);
            
            // Try to get existing profile
            const { data, error } = await supabase
                .from('user_profiles')
                .select('*')
                .eq('user_id', session.user.id)
                .single();

            if (error) {
                console.error('Error fetching profile:', error);
                
                // If profile doesn't exist, create one
                if (error.code === 'PGRST116') {
                    const { data: newProfile, error: insertError } = await supabase
                        .from('user_profiles')
                        .insert([
                            {
                                user_id: session.user.id,
                                full_name: name || '',
                                bio: bio || ''
                            }
                        ])
                        .single();

                    if (insertError) {
                        throw insertError;
                    }

                    console.log('Created new profile');
                    return;
                }
                
                throw error;
            }

            if (data) {
                setName(data.full_name || '');
                setBio(data.bio || '');
            }
        } catch (error) {
            console.error('Failed to fetch/create profile:', error);
            setUpdateMessage('Error loading profile. Please try again.');
        }
    };

    const fetchUserStats = async () => {
        if (!user) return;

        try {
            const { data: generatedData, error: generatedError } = await supabase
                .from('generated_questions')
                .select('id')
                .eq('user_id', user.id);

            if (generatedError) throw generatedError;

            const { data: savedData, error: savedError } = await supabase
                .from('saved_questions')
                .select('id')
                .eq('user_id', user.id);

            if (savedError) throw savedError;

            setStats({
                generatedCount: generatedData?.length || 0,
                savedCount: savedData?.length || 0
            });
        } catch (error) {
            console.error('Error fetching user stats:', error);
        }
    };

    const handleUpdateProfile = async () => {
        if (!user) return;

        try {
            // Store bio in Supabase
            const { error: supabaseError } = await supabase
                .from('user_profiles')
                .upsert({
                    user_id: user.id,
                    bio: bio,
                    updated_at: new Date().toISOString()
                });

            if (supabaseError) throw supabaseError;

            // Update name using Clerk
            await user.update({
                unsafeMetadata: {
                    fullName: name
                }
            });

            setUpdateMessage('Profile updated successfully!');
            setTimeout(() => {
                setUpdateMessage('');
            }, 3000);

        } catch (error) {
            console.error('Error updating profile:', error);
            setUpdateMessage('Failed to update profile. Please try again.');
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file || !user) return;

        try {
            await user.setProfileImage({ file });
            setUpdateMessage('Profile picture updated successfully!');
            setTimeout(() => {
                setUpdateMessage('');
            }, 3000);
        } catch (error) {
            console.error('Error uploading image:', error);
            setUpdateMessage('Failed to update profile picture. Please try again.');
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            await user.updatePassword({
                newPassword,
                currentPassword
            });
            setUpdateMessage('Password updated successfully!');
            setActiveForm(null);
            setNewPassword('');
            setCurrentPassword('');
        } catch (error) {
            console.error('Password change error:', error);
            
            // Handle specific password error cases
            const errorCode = error.errors?.[0]?.code || error.code;
            let errorMessage;
            
            switch (errorCode) {
                case 'form_password_length_too_short':
                    errorMessage = 'Passwords must be 8 characters or more.';
                    break;
                case 'form_password_insufficient_complexity':
                    errorMessage = 'Password must include a mix of letters, numbers, and special characters.';
                    break;
                case 'form_password_incorrect':
                    errorMessage = 'Failed to change password. Please check your current password.';
                    break;
                case 'form_password_pwned':
                    errorMessage = 'This password has been compromised. Please choose a different one.';
                    break;
                case 'form_identifier_exists':
                    errorMessage = 'This password was used recently. Please choose a different one.';
                    break;
                default:
                    errorMessage = error.message || 'Failed to change password. Please try again.';
            }
            
            setUpdateMessage(errorMessage);
        }
    };

    const handleChangeEmail = async (e) => {
        e.preventDefault();
        try {
            console.log('New email value:', newEmail);
            
            // First check if this email is already in the user's email addresses
            const existingEmails = user.emailAddresses;
            const existingEmail = existingEmails.find(
                email => email.emailAddress === newEmail
            );

            if (existingEmail) {
                if (!existingEmail.verified) {
                    // If email exists but unverified, prepare verification
                    await existingEmail.prepareVerification({
                        strategy: "email_code"
                    });
                    setEmailToVerify(existingEmail);
                    setShowVerificationInput(true);
                    setUpdateMessage('Verification email has been sent. Please check your inbox.');
                } else {
                    // If email exists and is verified, just set it as primary
                    await user.setPrimaryEmailAddress({
                        emailAddressId: existingEmail.id
                    });
                    await user.reload();
                    setUpdateMessage('Email set as primary successfully!');
                }
            } else {
                // If it's a completely new email, create it
                const newEmailAddress = await user.createEmailAddress({
                    email: newEmail
                });
                setEmailToVerify(newEmailAddress);
                setShowVerificationInput(true);
                setUpdateMessage('Verification email has been sent to your new address.');
            }
            
            setActiveForm(null);
            setNewEmail('');
        } catch (error) {
            console.error('Full error:', error);
            setUpdateMessage(error.message || 'Failed to change email. Please try again.');
            setTimeout(() => setUpdateMessage(''), 5000);
        }
    };

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        try {
            console.log('Attempting verification with code:', verificationCode);
            
            // Check if email is already verified
            if (emailToVerify.verification?.status === "verified") {
                // If already verified, just set as primary
                await user.update({
                    primaryEmailAddressId: emailToVerify.id
                });
            } else {
                // Otherwise, verify first
                await emailToVerify.attemptVerification({
                    code: verificationCode,
                    strategy: "email_code"
                });
                
                // Then set as primary
                await user.update({
                    primaryEmailAddressId: emailToVerify.id
                });
            }
            
            // Force a reload of the user object
            await user.reload();
            
            setUpdateMessage('Email set as primary successfully!');
            setShowVerificationInput(false);
            setVerificationCode('');
            setEmailToVerify(null);
        } catch (error) {
            console.error('Detailed verification error:', error);
            setUpdateMessage(error.message || 'Failed to verify email. Please try again.');
        }
    };

    const handleDeleteAccount = async () => {
        if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
            try {
                // Delete Supabase data first
                const { error: supabaseError } = await supabase
                    .from('user_profiles')
                    .delete()
                    .eq('user_id', user.id);

                if (supabaseError) throw supabaseError;

                // Sign out and redirect to sign up
                await clerk.signOut();
                navigate('/sign-up');
            } catch (error) {
                console.error('Error deleting account:', error);
                setUpdateMessage('Failed to delete account. Please try again.');
            }
        }
    };

    const handleSignOut = async () => {
        try {
            await clerk.signOut({
                redirectUrl: '/sign-in',
                sessionId: clerk.session?.id
            });
        } catch (error) {
            console.error('Error signing out:', error);
            setUpdateMessage('Failed to sign out. Please try again.');
        }
    };

    const togglePasswordForm = () => {
        setActiveForm(activeForm === 'password' ? null : 'password');
        setCurrentPassword('');
        setNewPassword('');
    };

    const toggleEmailForm = () => {
        setActiveForm(activeForm === 'email' ? null : 'email');
        setNewEmail('');
    };

    return (
        <div className="max-w-3xl mx-auto p-8 bg-white rounded-2xl shadow">
            <h1 className="text-2xl font-bold mb-6">User Profile</h1>

            <div className="flex items-start gap-4 mb-8">
                <div className="relative group">
                    <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-200">
                        {user?.imageUrl ? (
                            <img 
                                src={user.imageUrl} 
                                alt="Profile" 
                                className="w-full h-full object-cover"
                            />
                        ) : (
                            <div className="w-full h-full flex items-center justify-center text-gray-400">
                                <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </div>
                        )}
                    </div>
                    
                    <label className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 rounded-full cursor-pointer transition-opacity">
                        <span className="text-sm">Change</span>
                        <input 
                            type="file" 
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="hidden"
                        />
                    </label>
                </div>

                <div>
                    <h2 className="text-xl font-bold">
                        {user?.unsafeMetadata?.fullName || 'No name set'}
                    </h2>
                    <p className="text-gray-600">{user?.primaryEmailAddress?.emailAddress}</p>
                </div>
            </div>

            {/* {updateMessage && (
                <p className={`mb-4 text-sm ${updateMessage.includes('Failed') ? 'text-red-500' : 'text-green-500'}`}>
                    {updateMessage}
                </p>
            )} */}

            <div className="space-y-6 mb-8">
                <div>
                    <label className="block text-sm font-medium mb-2">Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 border rounded-lg"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-2">Bio</label>
                    <textarea
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        className="w-full p-2 border rounded-lg min-h-[100px]"
                        placeholder="Tell us about yourself..."
                    />
                </div>

                <button
                    onClick={handleUpdateProfile}
                    className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800"
                >
                    Update Profile
                </button>
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-bold mb-4">Usage Statistics</h2>
                <p>Questions Generated: {stats.generatedCount}</p>
                <p>Saved Questions: {stats.savedCount}</p>
            </div>

            <div>
                <h2 className="text-xl font-bold mb-4">Account Management</h2>
                <div className="flex flex-wrap gap-3">
                    <button 
                        onClick={togglePasswordForm}
                        className="px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                    >
                        Change Password
                    </button>
                    <button 
                        onClick={toggleEmailForm}
                        className="px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                    >
                        Change Email
                    </button>
                    <button 
                        onClick={handleDeleteAccount}
                        className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                        Delete Account
                    </button>
                </div>

                {/* Password Change Form */}
                {activeForm === 'password' && (
                    <form onSubmit={handleChangePassword} className="mt-6">
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="currentPassword">
                                    Current Password
                                </label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="newPassword">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-white bg-black rounded-md"
                                >
                                    Update Password
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setActiveForm(null);
                                        setCurrentPassword('');
                                        setNewPassword('');
                                        setUpdateMessage('');
                                    }}
                                    className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}

                {/* Email Change Form */}
                {activeForm === 'email' && (
                    <div className="mt-4">
                        <h3 className="font-medium mb-2">New Email Address</h3>
                        <input
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            className="w-full p-2 border rounded-lg mb-4"
                            placeholder="Enter new email address"
                        />
                        <div className="flex gap-2">
                            <button 
                                onClick={handleChangeEmail}
                                className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
                            >
                                Update Email
                            </button>
                            <button 
                                onClick={() => setActiveForm(null)}
                                className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}

                <button 
                    onClick={handleSignOut}
                    className="mt-6 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200"
                >
                    Sign Out
                </button>
            </div>

            {showVerificationInput && (
                <form onSubmit={handleVerifyCode} className="mt-4">
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="verificationCode" className="text-sm font-medium text-gray-700">
                            Enter Verification Code
                        </label>
                        <input
                            type="text"
                            id="verificationCode"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="Enter code from email"
                            required
                        />
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Verify Email
                        </button>
                    </div>
                </form>
            )}

            {updateMessage && (
                <div className={`mt-4 text-${updateMessage.toLowerCase().includes('success') ? 'green' : 'red'}-500`}>
                    {updateMessage}
                </div>
            )}
        </div>
    );
}

export default UserProfile; 